<template>
  <div class="container-fluid pt-2">
    <div class="d-flex justify-content-between align-items-end mb-2">
      <div class="d-flex">
        <router-link
          :to="{
            name: 'Workspace List',
            query: {
              shared:
                this.currentRouteName === 'Workspace Create Shared' ||
                this.currentRouteName === 'Workspace Edit Shared'
            }
          }"
          v-slot="{ href, route, navigate }"
          custom
        >
          <a :href="href" @click="navigate" class="mr-2"
            ><i class="fa fa-chevron-left mr-1"></i>Back</a
          >
        </router-link>
        <h2>{{ pageHeader }}</h2>
      </div>
      <div class="d-flex align-items-center">
        <b-button
          v-if="getScMode === 'SC_DASHBOARD'"
          @click="addItem"
          size="sm"
          variant="primary"
          class="mr-2"
          ><i class="fa fa-plus mr-1"></i>Add Panel
        </b-button>
        <b-form-input
          v-model="workspaceName"
          placeholder="Name your new Workspace"
          size="sm"
          style="width: 300px; margin-right: 0.5rem"
        ></b-form-input>

        <b-form-select
          v-if="
            currentRouteName === 'Workspace Create Shared' ||
            currentRouteName === 'Workspace Edit Shared'
          "
          size="sm"
          v-model="selectedRoleForSharedWorkspace"
          style="width: 200px; margin-right: 0.5rem"
        >
          <b-form-select-option :value="null"
            >Please select an role
          </b-form-select-option>
          <b-form-select-option
            v-for="role in rolesList"
            :key="'role_' + role.id"
            :value="role.id"
            >{{ role.name }}
          </b-form-select-option>
        </b-form-select>

        <b-button variant="success" size="sm" @click="validateWorkspace"
          ><i class="fa fa-save mr-1"></i>Save
        </b-button>
      </div>
    </div>
    <div class="workspace-editor scroller">
      <grid-layout
        :layout.sync="workspaceLayout.panels"
        :col-num="colNum"
        :row-height="rowHeight"
        :max-rows="maxRows"
        :is-draggable="draggable"
        :is-resizable="resizable"
        :vertical-compact="true"
        :use-css-transforms="true"
        @layout-ready="layoutReadyEvent"
        @layout-updated="layoutUpdatedEvent"
      >
        <grid-item
          v-for="item in workspaceLayout.panels"
          :static="getScMode === 'SC_DASHBOARD' ? item.static : true"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :minW="item.minW"
          :minH="item.minH"
          :key="'grid_' + item.i"
          :class="itemValid(item)"
          autoSize="false"
          dragIgnoreFrom="a,button,select"
        >
          <span
            class="workspace-panel-remove"
            v-if="getScMode === 'SC_DASHBOARD'"
            @click="removeItem(item.i)"
          >
            <i class="fa fa-times"></i>
          </span>

          <div class="workspace-panel-select-container">
            <b-container class="bv-example-row">
              <b-row class="justify-content-md-center">
                <b-col cols="12" md="auto">
                  <b-form-group
                    :label="'Panel ' + item.num"
                    :label-for="'panel_select_' + item.i"
                  >
                    <b-form-select
                      :disabled="getScMode !== 'SC_DASHBOARD'"
                      v-model="selectedPanels[item.i]"
                      :options="panelOptions"
                      :id="'panel_select_' + item.i"
                      class="workspace-panel-select"
                      @change="updatePanel"
                    >
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="justify-content-md-center">
                <b-col cols="12" md="auto">
                  <div class="d-flex">
                    <b-button
                      class="workspace-panel-select"
                      v-if="selectedPanels[item.i] == 'live_camera_view'"
                      v-b-modal="'form_search_device'"
                      :id="'panel_select_' + item.i"
                      @click="openSelectDeviceModal(item.i)"
                      variant="primary"
                    >
                      {{ getButtonText(item) }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="justify-content-md-center">
                <b-col cols="12" md="auto">
                  <div class="d-flex">
                    <b-button
                      class="workspace-panel-select"
                      v-if="selectedPanels[item.i] == 'vip_face_welcome'"
                      :id="'panel_select_' + item.i"
                      @click="openSelectedEditor(item.i)"
                      variant="primary"
                    >
                      Edit Background
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </grid-item>
      </grid-layout>
    </div>

    <b-modal
      @hide="resetFormAddDevice"
      ref="formAddDevice"
      title="Select a Camera"
      id="form_search_device"
      centered
      hide-footer
    >
      <div class="d-flex">
        <b-form-group
          id="input-group-search-ip"
          label="Search by IP:"
          label-for="input-search-ip"
          v-show="searchType != null"
        >
          <b-form-input
            id="input-search-ip"
            v-model="searchIP"
            type="text"
            placeholder="e.g. 192.168.0.123"
            required
            @change="searchDevices"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="device-available-container" v-show="searchType != null">
        <div>Devices available</div>
        <div class="device-options-container mt-2">
          <div
            v-for="(device, index) in searchDeviceOptions"
            :key="device.ip + '_' + index"
            class="device-option"
            :class="[selectedSearchDeviceOption == device.ip ? 'selected' : '']"
            @click="selectSearchDeviceOption(device)"
          >
            {{ device.ip }}
          </div>
        </div>
        <div v-show="formAddDeviceData.ip != null" class="pr-2">
          <h2>Camera Preview</h2>
          <div class="video-player-container">
            <div id="live_video_container" ref="liveVideoContainer"></div>
          </div>
          <div
            class="video-player-container d-flex align-items-center justify-content-center"
            v-if="liveVideoURL == null"
          >
            {{ liveVideoMessage }}
          </div>

          <div
            class="video-format-options-selector"
            v-if="formAddDeviceData.ip != null"
          >
            <b-form-select
              v-model="formAddDeviceData.video_format"
              :options="liveVideoFormatOptions"
              @change="getCameraDetails(formAddDeviceData.ip)"
              class="mt-2"
            ></b-form-select>
          </div>
        </div>
      </div>

      <b-form
        @submit="onSubmitFormAddDevice"
        :class="[selectedSearchDeviceOption != null ? '' : 'hide']"
        class="mt-2"
      >
        <b-form-group id="input-group-ip" label="IP:" label-for="input-ip">
          <b-form-input
            id="input-ip"
            v-model="formAddDeviceData.ip"
            type="text"
            :disabled="true"
          ></b-form-input>
        </b-form-group>
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>
    <PanelVipFaceWelcomeConfig></PanelVipFaceWelcomeConfig>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import VueGridLayout from "vue-grid-layout";
import Plyr from "plyr";
import Hls from "hls.js";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import { GridLayout, GridItem } from "vue-grid-layout";
import PanelVipFaceWelcomeConfig from "@/components/PanelVipFaceWelcome/PanelVipFaceWelcomeConfig";
let utils = require("@/assets/global/js/utils.js");

export default {
  name: "WorkspaceEditor",
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    PanelVipFaceWelcomeConfig
  },
  data: function () {
    return {
      workspaceID: null,
      workspaceName: null,
      showEditBackground: false,
      workspaceLayout: { panels: [] },
      defaultLayout: {
        panels: [
          { x: 0, y: 0, w: 6, h: 12, i: "0", num: 1, minW: 3, minH: 3 },
          { x: 6, y: 0, w: 6, h: 6, i: "1", num: 2, minW: 3, minH: 3 },
          { x: 6, y: 6, w: 6, h: 6, i: "2", num: 3, minW: 3, minH: 3 }
        ]
      },
      draggable: true,
      resizable: true,
      colNum: 12,
      rowHeight: 50,
      maxRows: 12,
      index: 0,
      pageHeader: "",
      panelOptions: [],
      selectedPanels: {},
      searchType: "camera",
      searchTypeOptions: [{ value: "camera", text: "Camera" }],
      searchIP: "",
      searchDeviceOptions: [],
      selectedSearchDeviceOption: null,
      formAddDeviceData: {
        id: null,
        ip: null,
        video_format: null
      },
      deviceType: "",
      liveVideoURL: "",
      liveVideoMessage: "",
      livePlayer: null,
      liveVideoFormatOptions: [
        { value: "mp4", text: "MP4" },
        { value: "hls", text: "HLS" },
        { value: "mpjpeg", text: "MPJPEG" },
        { value: "webm", text: "WEBM" }
      ],
      videoURLs: {
        mp4: null,
        hls: null,
        mpjpeg: null,
        webm: null
      },
      videoAuth: null,
      videoAuthSession: null,
      videoAuthData: null,
      selectedCameraIp: null,
      currentPanelIndex: null,
      cameraObj: null,
      rolesList: [],
      selectedRoleForSharedWorkspace: null,
      fixedSelectedRoleForSharedWorkspace: null,
      hasSelectedRoleForSharedWorkspace: false,
      unknownRole: {
        id: 0,
        name: "None",
        workspaces: null
      }
    };
  },
  computed: {
    ...mapGetters({
      isWorkspacesInitiated: "psim/isWorkspacesInitiated",
      getAPIServerURL: "session/getAPIServerURL",
      getScMode: "session/getScMode"
    }),
    currentRouteName() {
      return this.$route.name;
    }
  },
  mounted: function () {
    var workspace_id = this.$route.params["workspace_id"];
    if (workspace_id != undefined) {
      this.workspaceID = workspace_id;
    }
    this.initView();
    this.getRolesList();
  },
  methods: {
    initView: function () {
      try {
        // console.log("Viewing SettingsWorkspace - WorkspaceEditor");
        var $this = this;
        $this.$parent.pageTitle = "Workspace Editor";
        $this.fetchPanels();

        if ($this.workspaceID) {
          var workspace_data;

          if (this.currentRouteName === "Workspace Edit Shared") {
            $this.$parent.pageTitle = "Shared Workspace Editor";
            workspace_data = $this.$store.getters[
              "psim/getManageSharedWorkspace"
            ]($this.workspaceID);
            // console.log(workspace_data);
            for (var a in workspace_data.layout.panels) {
              workspace_data.layout.panels[a].minW = 3;
              workspace_data.layout.panels[a].minH = 3;
            }
            this.workspaceName = workspace_data.name;
            this.workspaceLayout = workspace_data.layout;
            this.pageHeader = "Update Workspace - " + workspace_data.name;
          } else {
            workspace_data = $this.$store.getters["psim/getWorkspace"](
              $this.workspaceID
            );
            // console.log(workspace_data);
            for (var b in workspace_data.layout.panels) {
              workspace_data.layout.panels[b].minW = 3;
              workspace_data.layout.panels[b].minH = 3;
            }
            this.workspaceName = workspace_data.name;
            this.workspaceLayout = workspace_data.layout;
            this.pageHeader = "Update Workspace - " + workspace_data.name;
          }
        } else {
          this.workspaceLayout = this.defaultLayout;

          if (this.currentRouteName === "Workspace Create Shared") {
            $this.$parent.pageTitle = "Shared Workspace Editor";
            this.pageHeader = "Create new Shared Workspace";
          } else {
            this.pageHeader = "Create new Workspace";
          }
        }

        $this.index = this.workspaceLayout.panels.length;
        for (var i in $this.workspaceLayout.panels) {
          var panel_index = $this.workspaceLayout.panels[i]["i"];
          var panel_value = $this.workspaceLayout.panels[i]["panel"] || null;
          $this.selectedPanels[panel_index] = panel_value;
        }
      } catch (e) {
        if (this.currentRouteName === "Workspace Edit Shared") {
          $this.$router
            .push({ name: "Workspace List", params: { shared: "true" } })
            .catch(() => {});
        } else {
          $this.$router.push({ name: "Workspace List" }).catch(() => {});
        }
      }

      // console.log(this.workspaceLayout);
    },
    getRolesList: function () {
      var $this = this;
      var API_URL = $this.getAPIServerURL + "/roles/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          var result = data.result;
          if (result != undefined && Array.isArray(result)) {
            this.rolesList = result;
            this.rolesList.push(this.unknownRole);

            if (this.currentRouteName === "Workspace Edit Shared") {
              for (let i = 0; i < this.rolesList.length; i++) {
                let roleObj = this.rolesList[i];

                if (roleObj.workspaces != null) {
                  let workspacesObj = roleObj.workspaces;

                  let idsArray = [];
                  if (
                    Object.prototype.hasOwnProperty.call(workspacesObj, "ids")
                  ) {
                    idsArray = workspacesObj["ids"];

                    if (idsArray.includes(this.workspaceID)) {
                      this.selectedRoleForSharedWorkspace = roleObj.id;
                      this.fixedSelectedRoleForSharedWorkspace = roleObj.id;
                      this.hasSelectedRoleForSharedWorkspace = true;
                      break;
                    }
                  }
                }
              }
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    fetchPanels: function () {
      var $this = this;
      var current_user = $this.$store.getters["session/getCurrentUser"];
      var available_panel_options =
        $this.$store.getters["psim/getPanelOptions"];
      // console.log(current_user);
      var API_URL =
        $this.getAPIServerURL + "/permissions/user/" + current_user.id + "/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          // console.log(data.panels)
          var panels_data = data.panels;
          if (panels_data != null && panels_data != undefined) {
            $this.panelOptions = [
              { value: null, text: "--- Please select an option ---" }
            ];

            for (var d in panels_data) {
              var panel_result = available_panel_options.filter(function (
                item
              ) {
                if (panels_data[d] == item.id) {
                  return true;
                }
                return false;
              });
              // console.log(panel_result);

              if (panel_result.length > 0) {
                var panel_data = {
                  value: panel_result[0].id,
                  text: panel_result[0].name
                };
                $this.panelOptions.push(panel_data);
              }
            }
          } else {
            $this.panelOptions = [
              { value: null, text: "--- No panels available ---" }
            ];
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    layoutReadyEvent: function (newLayout) {
      // console.log("Ready layout: ", newLayout)
    },
    layoutUpdatedEvent: function (newLayout) {
      // console.log("Updated layout: ", newLayout)
    },
    itemValid: function (item) {
      var $this = this;
      if (item.y >= 12) {
        return "workspace-panel-error";
      }
      return "";
    },
    addItem: function () {
      // Add a new item. It must have a unique key!
      this.workspaceLayout.panels.push({
        x: (this.workspaceLayout.panels.length * 2) % (this.colNum || 12),
        y: this.workspaceLayout.panels.length + (this.colNum || 12), // puts it at the bottom
        w: 3,
        h: 3,
        i: String(this.index),
        num: this.index + 1,
        minW: 3,
        minH: 3
      });
      this.selectedPanels[String(this.index)] = null;
      // Increment the counter to ensure key is always unique.
      this.index++;
    },
    removeItem: function (val) {
      const index = this.workspaceLayout.panels
        .map((item) => item.i)
        .indexOf(val);
      // console.log(this.workspaceLayout, val, index);
      this.workspaceLayout.panels.splice(index, 1);
      delete this.selectedPanels[String(val)];
    },
    validateWorkspace: function (e) {
      e.preventDefault();
      var $this = this;

      if (
        this.currentRouteName === "Workspace Edit Shared" ||
        this.currentRouteName === "Workspace Create Shared"
      ) {
        // Check for empty role
        if ($this.selectedRoleForSharedWorkspace == null) {
          this.$bvModal.msgBoxOk("Please select a role", {
            centered: true
          });
          return;
        }
      }

      // Check for empty name field
      if (this.workspaceName == null || this.workspaceName == "") {
        this.$bvModal.msgBoxOk("Please enter a name for your Workspace", {
          centered: true
        });
        return;
      }

      // Check for panels exceeding boundaries
      for (var i in this.workspaceLayout.panels) {
        if (this.workspaceLayout.panels[i].y >= 12) {
          this.$bvModal.msgBoxOk(
            "Panel " +
              this.workspaceLayout.panels[i].num +
              " exceeded boundaries",
            { centered: true }
          );
          return;
        }

        // disable empty camera id validation
        // if (this.workspaceLayout.panels[i].panel == "live_camera_view") {
        //   if (
        //       this.workspaceLayout.panels[i].camera_id == undefined ||
        //       this.workspaceLayout.panels[i].camera_id == null
        //   ) {
        //     this.$bvModal.msgBoxOk(
        //         "Please select a camera for Panel " +
        //         this.workspaceLayout.panels[i].num,
        //         {centered: true}
        //     );
        //   }
        // }
      }

      // Check for repeating panels and empty panels
      var exist_panels = [];
      // console.log(this.selectedPanels)
      for (var j in this.selectedPanels) {
        var panel_num = parseInt(j) + 1;
        var panel_value = this.selectedPanels[j];
        var panel_option = this.panelOptions.filter(function (item) {
          if (item.value == panel_value) {
            return true;
          }
          return false;
        });

        if (this.selectedPanels[j] == null) {
          this.$bvModal.msgBoxOk(
            "Please select a panel for Panel " + panel_num,
            { centered: true }
          );
          return;
        }
        if (
          exist_panels.indexOf(this.selectedPanels[j]) >= 0 &&
          this.selectedPanels[j] != "live_camera_view"
        ) {
          this.$bvModal.msgBoxOk(
            "Repeating " +
              panel_option[0].text +
              " at Panel " +
              panel_num +
              ". Already exists in the Workspace.",
            { centered: true }
          );
          return;
        } else {
          exist_panels.push(this.selectedPanels[j]);
        }
      }

      // SUCCESS - Set post data
      var API_URL = $this.getAPIServerURL + "/workspaces/";
      if ($this.workspaceID) {
        API_URL =
          $this.getAPIServerURL + "/workspaces/" + $this.workspaceID + "/";
      }
      const client = $this.$root.getAjaxFetchClient();
      var post_data = {
        name: this.workspaceName,
        layout: { panels: [] }
      };
      for (var k in this.workspaceLayout.panels) {
        var w_data = {};
        let panel_name = this.selectedPanels[this.workspaceLayout.panels[k].i];
        if (panel_name == "live_camera_view") {
          w_data = {
            x: this.workspaceLayout.panels[k].x,
            y: this.workspaceLayout.panels[k].y,
            w: this.workspaceLayout.panels[k].w,
            h: this.workspaceLayout.panels[k].h,
            i: this.workspaceLayout.panels[k].i,
            num: this.workspaceLayout.panels[k].num,
            panel: panel_name,
            camera_ip: this.workspaceLayout.panels[k].camera_ip,
            camera_id: this.workspaceLayout.panels[k].camera_id,
            video_format: this.workspaceLayout.panels[k].video_format
          };
        } else {
          w_data = {
            x: this.workspaceLayout.panels[k].x,
            y: this.workspaceLayout.panels[k].y,
            w: this.workspaceLayout.panels[k].w,
            h: this.workspaceLayout.panels[k].h,
            i: this.workspaceLayout.panels[k].i,
            num: this.workspaceLayout.panels[k].num,
            panel: panel_name
          };
        }

        post_data.layout.panels.push(w_data);
      }
      if ($this.workspaceID) {
        client
          .putRequest(API_URL, post_data)
          .then((data) => {
            var update_data = {
              id: $this.workspaceID,
              data: {
                name: post_data.name,
                layout: post_data.layout
              }
            };

            if (this.currentRouteName === "Workspace Edit Shared") {
              if (this.selectedRoleForSharedWorkspace !== null) {
                this.updateWorkspaceInRole($this.workspaceID);
              }

              $this.$store.dispatch(
                "psim/updateManageSharedWorkspace",
                update_data
              );

              let current_user = $this.$store.getters["session/getCurrentUser"];
              if (
                current_user.role_id === this.selectedRoleForSharedWorkspace
              ) {
                try {
                  let workspaces =
                    $this.$store.getters["psim/getSharedWorkspaces"];

                  let found = false;

                  for (let i = 0; i < workspaces.length; i++) {
                    if (workspaces.id === update_data.id) {
                      found = true;
                      break;
                    }
                  }

                  if (found) {
                    $this.$store.dispatch(
                      "psim/updateSharedWorkspace",
                      update_data
                    );
                  } else {
                    $this.$store.dispatch(
                      "psim/addSharedWorkspace",
                      update_data
                    );
                  }
                } catch (e) {
                  //
                }
              } else {
                try {
                  let workspaces =
                    $this.$store.getters["psim/getSharedWorkspaces"];

                  let found = false;

                  for (let i = 0; i < workspaces.length; i++) {
                    if (workspaces.id === update_data.id) {
                      found = true;
                      break;
                    }
                  }

                  // if(found){
                  //   $this.$store.dispatch("psim/remove", update_data);
                  // }
                } catch (e) {
                  //
                }
              }
            } else {
              $this.$store.dispatch("psim/updateWorkspace", update_data);
            }

            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Updated workspace successfully",
              message_type: "success"
            });

            location.reload();

            // TOCLEAN
            // } else {
            //     $this.$store.dispatch('session/addGlobalAlertMessage', {"message_text": data.detail, "message_type": "danger"});
            // }
          })
          .catch((err) => {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
            // if (err.status == 401) {
            //   $this.$store.dispatch("session/logoutSession");
            // }
          });
      } else {
        if (this.currentRouteName === "Workspace Create Shared") {
          API_URL += "shared/";

          client
            .postRequest(API_URL, post_data)
            .then((data) => {
              if (data.id != undefined) {
                var w_data = {
                  id: data.id,
                  name: data.name,
                  layout: data.layout
                };

                $this.$store.dispatch("psim/addManageSharedWorkspace", w_data);

                let current_user =
                  $this.$store.getters["session/getCurrentUser"];
                if (
                  current_user.role_id === this.selectedRoleForSharedWorkspace
                ) {
                  $this.$store.dispatch("psim/addSharedWorkspace", w_data);
                }

                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text:
                    "Added Shared Workspace " + data.name + " successfully",
                  message_type: "success"
                });

                if (this.selectedRoleForSharedWorkspace !== null) {
                  this.updateWorkspaceInRole(data.id);
                }

                $this.$router.push({ name: "Workspace List" }).catch(() => {});
              }

              if (data.detail != undefined) {
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: data.detail,
                  message_type: "danger"
                });
              }
            })
            .catch((err) => {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: err.detail,
                message_type: "danger"
              });
              // if (err.status == 401) {
              //   $this.$store.dispatch("session/logoutSession");
              // }
            });
        } else {
          client
            .postRequest(API_URL, post_data)
            .then((data) => {
              if (data.id != undefined) {
                var w_data = {
                  id: data.id,
                  name: data.name,
                  layout: data.layout
                };
                $this.$store.dispatch("psim/addWorkspace", w_data);
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text:
                    "Added Workspace " + data.name + " successfully",
                  message_type: "success"
                });

                $this.$router.push({ name: "Workspace List" }).catch(() => {});
              }
              if (data.detail != undefined) {
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: data.detail,
                  message_type: "danger"
                });
              }
            })
            .catch((err) => {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: err.detail,
                message_type: "danger"
              });
              // if (err.status == 401) {
              //   $this.$store.dispatch("session/logoutSession");
              // }
            });
        }
      }
    },
    updateWorkspaceInRole(workspace_id) {
      let API_URL =
        this.getAPIServerURL +
        "/roles/?role_id=" +
        this.selectedRoleForSharedWorkspace;
      const client = this.$root.getAjaxFetchClient();

      let selectedRoleObj = this.rolesList.find((role) => {
        return role.id === this.selectedRoleForSharedWorkspace;
      });

      let output = {};

      if (selectedRoleObj.workspaces != null) {
        let workspacesObj = selectedRoleObj.workspaces;

        let idsArray = [];
        if (Object.prototype.hasOwnProperty.call(workspacesObj, "ids")) {
          idsArray = workspacesObj["ids"];

          if (!idsArray.includes(workspace_id)) {
            idsArray.push(workspace_id);
          }

          workspacesObj["ids"] = idsArray;
        } else {
          workspacesObj["ids"] = [workspace_id];
        }

        output["workspaces"] = workspacesObj;
      } else {
        output = {
          workspaces: {
            ids: [workspace_id]
          }
        };
      }

      client.putRequest(API_URL, output).then(() => {
        this.getRolesList();

        if (this.hasSelectedRoleForSharedWorkspace) {
          if (
            this.fixedSelectedRoleForSharedWorkspace !==
            this.hasSelectedRoleForSharedWorkspace
          ) {
            this.removeWorkspaceFromPreviousRole(workspace_id);
          }
        }
      });
    },
    removeWorkspaceFromPreviousRole(workspace_id) {
      let API_URL =
        this.getAPIServerURL +
        "/roles/?role_id=" +
        this.fixedSelectedRoleForSharedWorkspace;
      const client = this.$root.getAjaxFetchClient();

      let selectedRoleObj = this.rolesList.find((role) => {
        return role.id === this.fixedSelectedRoleForSharedWorkspace;
      });

      let output = {};

      if (selectedRoleObj.workspaces != null) {
        let workspacesObj = selectedRoleObj.workspaces;

        let idsArray = [];
        if (Object.prototype.hasOwnProperty.call(workspacesObj, "ids")) {
          idsArray = workspacesObj["ids"];

          if (idsArray.includes(workspace_id)) {
            for (let i = 0; i < idsArray.length; i++) {
              if (idsArray[i] === workspace_id) {
                idsArray.splice(i, 1);
              }
            }

            workspacesObj["ids"] = idsArray;
            output["workspaces"] = workspacesObj;

            client.putRequest(API_URL, output).then(() => {
              this.getRolesList();
            });
          }
        }
      }
    },
    updatePanel: function (e) {
      this.$forceUpdate();
    },
    openSelectedEditor: function (index) {
      console.log("Showing");
      this.$root.$emit(
        "bv::show::modal",
        "modal-welcome-config",
        "#panel_select_" + index
      );
      this.showEditBackground = true;
    },
    // --- Add device ---
    openSelectDeviceModal: function (panel_index) {
      let $this = this;
      $this.currentPanelIndex = panel_index;
      if ($this.workspaceLayout.panels[panel_index] != undefined) {
        if (
          $this.workspaceLayout.panels[panel_index].camera_id != undefined &&
          $this.workspaceLayout.panels[panel_index].camera_id != null
        ) {
          $this.selectedSearchDeviceOption =
            $this.workspaceLayout.panels[panel_index].camera_ip;
          $this.formAddDeviceData.id =
            $this.workspaceLayout.panels[panel_index].camera_id;
          $this.formAddDeviceData.ip =
            $this.workspaceLayout.panels[panel_index].camera_ip;
          $this.formAddDeviceData.video_format =
            $this.workspaceLayout.panels[panel_index].video_format;
        } else {
          $this.selectedSearchDeviceOption = null;
          $this.formAddDeviceData.id = null;
          $this.formAddDeviceData.ip = null;
          $this.formAddDeviceData.video_format = null;
        }
      }
      $this.searchDevices();
    },
    searchDevices: function (e) {
      var $this = this;

      var API_URL = $this.getAPIServerURL + "/api/monitorservice/status/";
      var RESULT_CATEGORY = "";
      var STORE_GETTER = "";
      var DEVICE_TYPE = "";

      $this.searchDeviceOptions = [];
      // console.log("searchDevices", $this.searchType);
      if ($this.searchType == "camera") {
        RESULT_CATEGORY = "DEV_CAM";
        STORE_GETTER = "psim/getCamera";
        DEVICE_TYPE = "camera";
      }

      // console.log("API_URL");
      // console.log(API_URL);

      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((mdata) => {
          // console.log("mdata");
          // console.log(mdata);

          let category_result = null;
          for (var service_url in mdata["result"]) {
            try {
              category_result =
                mdata["result"][service_url]["result"][RESULT_CATEGORY];
              //
              // console.log("category_result");
              // console.log(category_result);
            } catch (err) {
              //
            }

            if (category_result != null) {
              for (var i in category_result) {
                var device_url = i;
                var device_data = category_result[i];
                var device_ip = new URL(device_url).host;
                if (device_data.details != undefined) {
                  if (device_data.details.ip != undefined) {
                    device_ip = device_data.details.ip;
                  }
                }
                if ($this.$store.getters[STORE_GETTER](device_ip) == null) {
                  var device_result = {
                    id: device_url,
                    ip: device_ip,
                    type: DEVICE_TYPE,
                    status: device_data["status"],
                    data: {
                      server_type:
                        device_data["monitor_type"] == undefined
                          ? null
                          : device_data["monitor_type"],
                      server_url:
                        device_data["monitor_url"] == undefined
                          ? null
                          : device_data["monitor_url"],
                      details:
                        device_data["details"] == undefined
                          ? null
                          : device_data["details"],
                      meta:
                        device_data["meta"] == undefined
                          ? null
                          : device_data["meta"]
                    }
                  };
                  $this.searchDeviceOptions.push(device_result);
                }
              }
            }
          }

          $this.searchDeviceOptions.sort(function (a, b) {
            const numA = Number(
              a["ip"]
                .split(".")
                .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
                .reduce((a, v) => ((a += v), a), 0)
            );
            const numB = Number(
              b["ip"]
                .split(".")
                .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
                .reduce((a, v) => ((a += v), a), 0)
            );
            return numA - numB;
          });

          if (device_result.data.server_type == "DIGIFORT") {
            var stream_url = [];
            stream_url = Object.keys(device_result.data.details.stream_url);
            $this.liveVideoFormatOptions.filter(function (obj) {
              obj.value;
              //return !!obj.options[value];
              stream_url.filter(function (obj2) {
                if (obj.value === obj2) {
                  $this.liveVideoFormatOptions = [];
                  var data_live_video = {
                    value: obj2,
                    text: "MPJPEG"
                  };
                  $this.liveVideoFormatOptions.push(data_live_video);
                }
              });
            });
          }
        })
        .catch((err) => {
          // console.log("Error: No ");
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    selectSearchDeviceOption: function (device_data) {
      this.selectedSearchDeviceOption = device_data.ip;
      this.formAddDeviceData.id = device_data.id;
      this.formAddDeviceData.ip = device_data.ip;
      this.formAddDeviceData.video_format = null;
    },
    onSubmitFormAddDevice: function (e) {
      e.preventDefault();
      var $this = this;

      // $this.selectedCameraIp = this.formAddDeviceData.ip;

      if ($this.currentPanelIndex != null) {
        if (
          $this.workspaceLayout.panels[$this.currentPanelIndex] != undefined
        ) {
          $this.workspaceLayout.panels[$this.currentPanelIndex]["camera_id"] =
            $this.formAddDeviceData.id;
          $this.workspaceLayout.panels[$this.currentPanelIndex]["camera_ip"] =
            $this.formAddDeviceData.ip;
          $this.workspaceLayout.panels[$this.currentPanelIndex][
            "video_format"
          ] = $this.formAddDeviceData.video_format;
        }
      }
      $this.$refs.formAddDevice.hide();
      $this.currentPanelIndex = null;
    },
    resetFormAddDevice: function (e) {
      this.formAddDeviceData.id = null;
      this.formAddDeviceData.ip = null;
      this.formAddDeviceData.type = null;
      this.formAddDeviceData.name = null;
      this.formAddDeviceData.data = null;

      this.selectedSearchDeviceOption = null;
      this.searchType = "camera";
      this.searchIP = "";
      this.searchDeviceOptions = [];
    },
    // --- Update device ---
    showUpdateDeviceForm: function (device_data) {
      this.formUpdateDeviceData.ip = device_data.ip;
      this.formUpdateDeviceData.type = device_data.type;
      this.formUpdateDeviceData.name = device_data.name;
    },
    onSubmitFormUpdateDevice: function (e) {
      e.preventDefault();
      var $this = this;
      if (
        $this.formUpdateDeviceData.name == null ||
        $this.formUpdateDeviceData.name == ""
      ) {
        $this.formUpdateDeviceData.name = $this.formUpdateDeviceData.ip;
      }
      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/floors/" +
        $this.floorID +
        "/devices";
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, $this.formUpdateDeviceData)
        .then((data) => {
          if (data == null) {
            var update_data = {
              device_type: $this.formUpdateDeviceData.type,
              device_id: $this.formUpdateDeviceData.id,
              data: {
                name: $this.formUpdateDeviceData.name
              }
            };
            $this.$store.dispatch("psim/updateDevice", update_data);
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Updated device successfully",
              message_type: "success"
            });
          }

          // TOCLEAN
          // } else {
          //     $this.$store.dispatch('session/addGlobalAlertMessage', { "message_text": data.detail, "message_type": "danger" });
          // }

          $this.fetchDevices();
          $this.$refs.formUpdateDevice.hide();
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    resetFormUpdateDevice: function (e) {
      this.formUpdateDeviceData.ip = null;
      this.formUpdateDeviceData.type = null;
      this.formUpdateDeviceData.name = null;
    },
    resetLivePlayer: function () {
      var $this = this;
      try {
        if ($this.livePlayer != null) {
          $this.livePlayer.destroy();
          $this.livePlayer = null;
        }
      } catch (err) {
        //
      }

      if (
        $this.liveVideoURL != null &&
        $this.$refs.liveVideoContainer != null
      ) {
        while ($this.$refs.liveVideoContainer.hasChildNodes()) {
          $this.$refs.liveVideoContainer.removeChild(
            $this.$refs.liveVideoContainer.lastChild
          );
        }
      }

      $this.liveVideoURL = null;
      $this.liveVideoMessage = "Loading camera stream";
    },
    getCameraDetails: function (camera_ip) {
      var $this = this;
      let device_search;

      device_search = $this.$store.getters["device/filterCameras"]({
        ip: camera_ip
      });
      // console.log(device_search)
      if (device_search.length > 0) {
        $this.deviceObj = device_search[0];
        // console.log($this.deviceObj)
        $this.getVideoStream();
      }
    },
    getVideoStream: function () {
      var $this = this;
      var stream_urls = null;
      var mpjpeg_stream = null;
      var has_stream = false;

      if ($this.deviceObj.monitor_type === "DIGIFORT") {
        stream_urls = $this.deviceObj.data.details.stream_url;
        mpjpeg_stream = stream_urls.mpjpeg;

        if (mpjpeg_stream) {
          has_stream = true;
          $this.videoURLs.mpjpeg = mpjpeg_stream ? mpjpeg_stream : null;
        }

        if (has_stream == false) {
          $this.liveVideoMessage = "No camera stream available";
        } else {
          $this.videoAuthSession =
            $this.deviceObj.data.details.authsession.AuthSession;
          $this.videoAuthData =
            $this.deviceObj.data.details.authsession.AuthData;
          $this.loadLivePlayer();
        }
      } else {
        stream_urls = $this.deviceObj.data.details.stream_url;
        mpjpeg_stream = stream_urls.mpjpeg;
        var mp4_stream = stream_urls.mp4;
        var hls_stream = stream_urls.hls;
        var webm_stream = stream_urls.webm;

        if (mp4_stream || hls_stream || mpjpeg_stream || webm_stream) {
          has_stream = true;
          $this.videoURLs.mp4 = mp4_stream ? mp4_stream : null;
          $this.videoURLs.hls = hls_stream ? hls_stream : null;
          $this.videoURLs.mpjpeg = mpjpeg_stream ? mpjpeg_stream : null;
          $this.videoURLs.webm = webm_stream ? webm_stream : null;
        }

        if (has_stream == false) {
          $this.liveVideoMessage = "No camera stream available";
        } else {
          $this.videoAuth = $this.deviceObj.data.details.authkey;
          $this.loadLivePlayer();
        }
      }
    },
    loadLivePlayer: function () {
      var $this = this;
      let video_url = null;
      if ($this.formAddDeviceData.video_format == "mp4") {
        video_url = $this.videoURLs.mp4;
      } else if ($this.formAddDeviceData.video_format == "hls") {
        video_url = $this.videoURLs.hls;
      } else if ($this.formAddDeviceData.video_format == "mpjpeg") {
        video_url = $this.videoURLs.mpjpeg;
      } else if ($this.formAddDeviceData.video_format == "webm") {
        video_url = $this.videoURLs.webm;
      }

      $this.resetLivePlayer();

      try {
        let live_stream_url = new URL(video_url);
        if ($this.deviceObj.monitor_type === "DIGIFORT") {
          if ($this.videoAuthSession && $this.videoAuthData) {
            live_stream_url.searchParams.set(
              "AuthSession",
              $this.videoAuthSession
            );
            live_stream_url.searchParams.set("AuthData", $this.videoAuthData);
          }
        } else {
          if ($this.videoAuth) {
            live_stream_url.searchParams.set("auth", $this.videoAuth);
          }
        }

        $this.liveVideoURL = live_stream_url.toString();

        let live_video;
        let live_video_source;
        let live_player;
        // console.log(live_stream_url.toString())
        if ($this.formAddDeviceData.video_format == "mp4") {
          // live
          live_video = document.createElement("video");
          live_video.setAttribute("playsinline", "");
          live_video.setAttribute("controls", "");
          live_video.setAttribute("crossorigin", "");
          live_video.setAttribute("muted", "");
          $this.$refs.liveVideoContainer.appendChild(live_video);

          live_video_source = document.createElement("source");
          live_video_source.setAttribute("src", $this.liveVideoURL);
          live_video_source.setAttribute("type", "video/mp4");
          live_video.appendChild(live_video_source);

          live_player = new Plyr(live_video, {});
          $this.livePlayer = live_player;
        } else if ($this.formAddDeviceData.video_format == "webm") {
          // live
          live_video = document.createElement("video");
          live_video.setAttribute("playsinline", "");
          live_video.setAttribute("controls", "");
          live_video.setAttribute("crossorigin", "");
          live_video.setAttribute("muted", "");
          $this.$refs.liveVideoContainer.appendChild(live_video);

          live_video_source = document.createElement("source");
          live_video_source.setAttribute("src", $this.liveVideoURL);
          live_video_source.setAttribute("type", "video/webm");
          live_video.appendChild(live_video_source);

          live_player = new Plyr(live_video, {});
          $this.livePlayer = live_player;
        } else if ($this.formAddDeviceData.video_format == "hls") {
          // live
          live_video = document.createElement("video");
          live_video.setAttribute("playsinline", "");
          live_video.setAttribute("controls", "");
          live_video.setAttribute("crossorigin", "");
          live_video.setAttribute("muted", "");
          $this.$refs.liveVideoContainer.appendChild(live_video);

          const hls1 = new Hls();
          // hls1.loadSource("https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8");
          hls1.loadSource($this.liveVideoURL);
          hls1.attachMedia(live_video);
          live_player = new Plyr(live_video, {});
          $this.livePlayer = live_player;
        } else if ($this.formAddDeviceData.video_format == "mpjpeg") {
          // live
          live_video = document.createElement("img");
          live_video.classList.add("img-fluid");
          live_video.setAttribute("src", $this.liveVideoURL);
          live_video.setAttribute("alt", "MPJPEG Player");

          $this.$refs.liveVideoContainer.appendChild(live_video);

          $this.livePlayer = null;
        }
        // console.log(live_player);
      } catch (err) {
        $this.liveVideoMessage = "No camera stream available";
      }
    },
    getButtonText: function (item) {
      if (item.camera_ip != null && item.video_format != null) {
        return item.camera_ip + " (" + item.video_format.toUpperCase() + ")";
      } else {
        return "Select Camera";
      }
    }
  }
};
</script>
<style lang="scss">
@import "./SettingsWorkspace.scss";
</style>
